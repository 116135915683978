import React from "react";
import { Link } from "gatsby";
import * as styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px 0px",
        }}
        className={styles.container}
      >
        <div className={styles.footerSection}>
          <ul>
            <li>
              <Link to="/o-questy/">O Questy</Link>
            </li>
            <li>
              <Link to="/kariera/">Kariera</Link>
            </li>
            <li>
              <Link to="/dla-partnera/">Dla partnera</Link>
            </li>
            <li>
              <Link to="/bezpieczenstwo-oprogramowania/">Bezpieczeństwo</Link>
            </li>
            <li>
              <Link to="/metody-finansowania/">Finansowanie projektów</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <ul>
            <li>
              <Link to="/wspierane-obszary/">Wspierane obszary</Link>
            </li>
            <li>
              <Link to="/sprzedaz-i-marketing/">Sprzedaż i marketing</Link>
            </li>
            <li>
              <Link to="/samoobsluga-b2b/">Samoobsługa B2B</Link>
            </li>
            <li>
              <Link to="/zarzadzanie-uslugami/">Zarządzanie usługami</Link>
            </li>
            <li>
              <Link to="/rozwiazania-dedykowane/">Rozwiązania dedykowane</Link>
            </li>
          </ul>
        </div>
                <div className={styles.footerSection}>
          <ul>
            <li>
              <Link to="/baza-wiedzy/">Baza wiedzy</Link>
            </li>
            <li>
              <Link to="/blog/">Blog Questy</Link>
            </li>
            <li>
              <Link to="/vlog/">Vlog Questy</Link>
            </li>
            <li>
              <Link to="/wywiady/">Wywiady</Link>
            </li>
          </ul>
        </div>
        <div id={styles.address} className={styles.footerSection}>
          <p>Questy</p>
          <p>ul. Głogowska 31/33</p>
          <p>60-702 Poznań</p>
          <p>+48 506 504 540</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
