import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import { Box, Text } from "@chakra-ui/core";
import GlobalBanner from "./GlobalBanner";
import Header from "./header";
import "./layout.css";
import Footer from "./footer";
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <CookieConsent
          buttonText="Ok, rozumiem"
          style={{
            background: "#fafafa",
            color: "black",
          }}
          buttonStyle={{
            background: "#f0f0f0",
          }}
        >
          Serwis wykorzystuje pliki cookies. Korzystając ze strony wyrażasz
          zgodę na wykorzystywanie plików cookies.{" "}
          <Link to="/polityka-cookies/">Dowiedz się więcej.</Link>
        </CookieConsent>
           <GlobalBanner
        localStorageKey="3cf7e0b1-4d25-42e3-bfe7-2fd0e17f06a0"
          backgroundComponent={
            <Box
              backgroundColor="#64b72c"
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
            />
          }
        >
          <Box w="full" textAlign="center" py={2}>
            <div
              style={{
                color: "#ffffff",
              }}
            >

            <p><Link style={{
              color:"#ffffff"
              }} to="/analiza-przedwdrozeniowa/"><strong>Zobacz najnowszy wywiad:</strong> „Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?”.</Link>
            </p>
 
            </div>
          </Box>
        </GlobalBanner>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            // maxWidth: 1170,
            // padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <Footer />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
