import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
import { Link } from "gatsby";
import * as styles from "./menu.module.css";
import en from "../images/EN.ico";

const Menu = ({ className }) => (
  <ul className={`${styles.menu} ${className}`}>
    <li className={styles.dropdown}>
      <Link to="/wspierane-obszary/">
        Wspierane obszary <FaAngleDown />
      </Link>
      <div className={styles.dropdownContent}>
        <ul className={styles.dropdownMenu}>
          <li>
            <Link title="Sprzedaż i marketing" to="/sprzedaz-i-marketing/">
              Sprzedaż i marketing
            </Link>
          </li>
          <li>
            <Link title="Samoobsługa B2B" to="/samoobsluga-b2b/">
              Samoobsługa B2B
            </Link>
          </li>
          <li>
            <Link title="Zarządzanie usługami" to="/zarzadzanie-uslugami/">
              Zarządzanie usługami
            </Link>
          </li>
          <li>
            <Link title="Rozwiązania dedykowane" to="/rozwiazania-dedykowane/">
              Rozwiązania dedykowane
            </Link>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <Link to="/o-questy/">O Questy</Link>
    </li>
    <li>
      <Link to="/kariera/">Kariera</Link>
    </li>
    <li>
      <Link to="/baza-wiedzy/">Baza wiedzy</Link>
    </li>
    <li>
      <Link className={styles.special} to="/kontakt/">
        Kontakt
      </Link>
    </li>
    <li></li>
    <Link
      to="https://en.questy.pl/"
      style={{
        color: `white`,
        textDecoration: `none`,
        display: `flex`,
        alignItems: "center",
      }}
    >
      <img
        style={{
          margin: 0,
          maxWidth: "24px",
        }}
        src={en}
      />
    </Link>
  </ul>
);

export default Menu;
