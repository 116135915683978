import { Box, CloseButton, Flex } from "@chakra-ui/core";
import { css } from "@emotion/core";
import React, { useState, useEffect } from "react";

interface GlobalBannerProps {
  localStorageKey?: string;
  backgroundComponent?: React.ReactNode;
  isEnabled?: boolean;
}

const GlobalBanner: React.FC<GlobalBannerProps> = ({
  localStorageKey = "globalBannerClosed",
  backgroundComponent = null,
  isEnabled = true,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const isBannerClosed = JSON.parse(localStorage.getItem(localStorageKey));
    if (isBannerClosed) {
      setIsOpen(false);
    }
  }, [isOpen]);

  const closeGlobalBanner = () => {
    setIsOpen(false);
    localStorage.setItem(localStorageKey, "true");
  };

  if (!isOpen) {
    return null;
  }

  if (!isEnabled) {
    return null;
  }

  return (
    <Flex
      css={css`
        .global-banner__close-btn {
          background: none;
          border: none;
        }
      `}
      className="global-banner"
      minH="50px"
      alignItems="center"
      position="relative"
    >
      {backgroundComponent}
      <Box maxW="1170px" w="full" mx="auto" position="relative">
        <Box className="global-banner__content">{children}</Box>
        <CloseButton
          position="absolute"
          top="calc(50% - 12px)"
          right={0}
          cursor="pointer"
          className="global-banner__close-btn"
          size="sm"
          onClick={closeGlobalBanner}
        />
      </Box>
    </Flex>
  );
};

export default GlobalBanner;
